import { faCircleCheck, faCircleExclamation, faCircleQuestion, faClose, faEnvelope, faEye, faHammerBrush, faHandshake, faMoneyBill, faPencil, faPeople, faPhone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { colours } from "src/app/Styles";
import { EventType, RentChargeStatus, RentSchedule } from "src/app/enums/Index";
import { FormatDate, FormatNumber } from "src/app/helpers/Index";
import { PropertyEvent, RentCharge, RentPayment, Tenant } from "src/app/interfaces/Index";
import { RentChargeService, TenantService } from "src/app/services/Index";

export const AdminTenantsId = ({toast}: any) => {
	const navigate = useNavigate();
	const params = useParams();
	const [editableTenant, setEditableTenant] = useState<Tenant>(new Tenant());
	const [isLoading, setIsLoading] = useState(false);
	const [isRemoveTenantLoading, setIsRemoveTenantLoading] = useState(false);
	const [isTenantUpdating, setIsTenantUpdating] = useState(false);
	const [modalPropertyEvents, setModalPropertyEvents] = useState<PropertyEvent[]>([]);
	const [tenant, setTenant] = useState<Tenant>(new Tenant());
	const [rent, setRent] = useState<RentCharge[]>();
	const [showEditTenantModal, setShowEditTenantModal] = useState(false);
	const [showEventsModal, setShowEventsModal] = useState(false);
	const [showRemoveTenantModal, setShowRemoveTenantModal] = useState(false);
	const [showViewTenancyModal, setShowViewTenancyModal] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getTenant();
	}, []);

	const filterPropertyEvents = (data: Tenant) => {
		const startingDate = data.tenancyAgreement?.startDate;
		let events = [];
		if (startingDate && data.tenancyAgreement?.property?.propertyEvents) events = data.tenancyAgreement?.property?.propertyEvents?.filter((events) => new Date(events.createdAt).getTime() > new Date(startingDate).getTime());
		else events = data.tenancyAgreement?.property?.propertyEvents || [];
		events.sort((a: PropertyEvent, b: PropertyEvent) => {
			const aDateDue = new Date(a.createdAt);
			const bDateDue = new Date(b.createdAt);
			if (aDateDue.getTime() > bDateDue.getTime()) return -1;
			else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
			else return 0;
		});
		setModalPropertyEvents(events);
	}

	const getRentFromAgreement = async (agreementId: number) => {
		const res = await RentChargeService.getByAgreement(agreementId);
		switch(res.status) {
			case 200:
				const data = await res.json()
				data.sort((a: RentCharge, b: RentCharge) => {
					const aDateDue = new Date(a.dateDue);
					const bDateDue = new Date(b.dateDue);
					if (aDateDue.getTime() > bDateDue.getTime()) return -1;
					else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
					else return 0;
				});
				setRent(data);
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const getTenant = async () => {
		const {id} = params;
		const res = await TenantService.getFullDetails(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setTenant(data);
				setEditableTenant(data);
				getRentFromAgreement(data?.tenancyAgreementId);
				filterPropertyEvents(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const removeTenant = async () => {
		setIsRemoveTenantLoading(true);
		const res = await TenantService.deleteSingleById(tenant.id);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Tenant removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				navigate("/admin/main/tenants");
				setIsRemoveTenantLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemoveTenantLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemoveTenantLoading(false);
				break;
		}
	}

	const renderEditTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowEditTenantModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => saveTenant()}></Button>
			</>
		);
	}

	const renderRemoveTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemoveTenantModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeTenant()}></Button>
			</>
		);
	}

	const renderViewTenancyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-eye" label="View Details" onClick={() => navigate(`/admin/main/tenancy-agreement/${tenant?.tenancyAgreement?.id}`)}></Button>
			</>
		);
	}

	const saveTenant = async () => {
		setIsTenantUpdating(true);
		const res = await TenantService.updateSingleById(editableTenant.id, editableTenant);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getTenant();
				setIsTenantUpdating(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsTenantUpdating(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsTenantUpdating(false);
				break;
		}
	}

	// Returns true if there is a valid/active tenancy agreement
	const tenancyAgreementCheck = () => {
		if (tenant.id === 0) return false;
		else if (tenant.tenancyAgreement === undefined || tenant.tenancyAgreement === null) return false;
		else if (tenant.tenancyAgreement) {
			const startTime = new Date(tenant.tenancyAgreement.startDate).getTime();
			const now = new Date().getTime();
			if (now > startTime) {
				const endTime = new Date(tenant.tenancyAgreement.contractValidUntil).getTime();
				if (now < endTime) return true;
			} else return true;
		} else return false;
	}

	return (
		<>
			<Row>
				<Col style={{marginBottom: 10}}>
					{tenancyAgreementCheck() === false &&
						<Button onClick={() => setShowRemoveTenantModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faClose} style={{fontSize: 15, marginRight: 10, width: 15}}/>Remove Tenant</Button>
					}
					<Button onClick={() => setShowEditTenantModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black}}><FontAwesomeIcon icon={faPencil} style={{fontSize: 15, marginRight: 10, width: 15}}/>Edit Tenant</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="card" style={{display: "flex", flexDirection: "row", marginBottom: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
							<span style={{fontSize: 25, fontWeight: "bold"}}>{`${tenant.name}`}</span>
							{tenant && tenant.tenancyAgreement &&
								<span>{`${tenant?.tenancyAgreement?.property?.addressLine1}, ${tenant?.tenancyAgreement?.property?.town}, ${tenant?.tenancyAgreement?.property?.postcode}`}</span>
							}
						</div>
						<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
							<a href={`tel:${tenant.phoneNumber}`} style={{color: colours.black, cursor: "pointer", marginRight: 20}}>
								<FontAwesomeIcon icon={faPhone} style={{fontSize: 35}}/>
							</a>
							<a href={`mailto:${tenant.emailAddress}`} style={{color: colours.black, cursor: "pointer"}}>
								<FontAwesomeIcon icon={faEnvelope} style={{fontSize: 35}}/>
							</a>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={5}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHandshake} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Tenancy Details</span>
						</div>
						<span>Agreement Number: {tenant?.tenancyAgreement?.id}</span>
						<span>Primary Contact: {tenant?.tenancyAgreement?.primaryContactTenant?.name}</span>
						<span>Contract Start: {FormatDate(tenant?.tenancyAgreement?.startDate)}</span>
						<span>Valid Until: {FormatDate(tenant?.tenancyAgreement?.contractValidUntil)}</span>
						<span>Next Charge: {FormatDate(tenant?.tenancyAgreement?.generateRentChargeOn)}</span>
						{tenant && tenant.tenancyAgreement &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowViewTenancyModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Agreement</span>
							</div>
						}
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faPeople} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Linked Tenants</span>
							<DataTable scrollable scrollHeight="flex" value={tenant?.tenancyAgreement?.tenants}>
								<Column field="name" header="Name" sortable></Column>
								{/* <Column body={this.customerProfileButtons}></Column> */}
							</DataTable>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHammerBrush} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Events</span>
						</div>
						<span>Total Events: {modalPropertyEvents.length}</span>
						{modalPropertyEvents.length > 0 &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowEventsModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Events</span>
							</div>
						}
					</div>
				</Col>
				<Col xs={7}>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faMoneyBill} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Rent Book</span>
							<DataTable scrollable scrollHeight="flex" value={rent}>
								<Column body={(rowData) => FormatDate((rowData.dateDue))} field="dateDue" header="Due Date" sortable style={{width: 125}}></Column>
								<Column field="rentAmount" header="Rent Amount" sortable style={{width: 155}}></Column>
								<Column field="rentPayments[0].paymentValue" header="Rent Paid" sortable style={{width: 125}} body={(rowData) => {
									const totalPaid = rowData.rentPayments.length > 0 ? rowData.rentPayments.map((payment: RentPayment) => payment.paymentValue).reduce((a: number, b: number) => a + b) : 0;
									return FormatNumber(totalPaid);
								}}></Column>
								<Column body={(rowData) => FormatDate((rowData.datePaid))} field="datePaid" header="Paid On" sortable style={{width: 125}}></Column>
								<Column field="tenant?.tenancyAgreement?.primaryContactTenant" header="Tenant" sortable></Column>
								<Column field="chargeStatus" header="Status" sortable style={{width: 120}} body={(rowData) => {
									switch (rowData.chargeStatus as RentChargeStatus) {
										case RentChargeStatus.Paid:
											return (<span style={{color: "#7FD764"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Paid</span>);
										case RentChargeStatus.Due:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Due</span>);
										case RentChargeStatus.Late:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Late</span>);
										case RentChargeStatus.OverDue:
											return (<span style={{color: "#F0552A"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Overdue</span>);
										case RentChargeStatus.NotDue:
											return (<span><FontAwesomeIcon icon={faCircleQuestion} style={{fontSize: 15, width: 15}}/> Not Due</span>);
									}
								}}></Column>
							</DataTable>
						</div>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid edit-tenant" draggable={false} footer={() => renderEditTenantModalFooter()} header="Edit Tenant" modal onHide={() => setShowEditTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEditTenantModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="name">Name</label>
							<InputText id="name" onChange={(e) => setEditableTenant({...editableTenant, name: e.target.value})} value={editableTenant.name}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="emailAddress">Email Address</label>
							<InputText id="emailAddress" onChange={(e) => setEditableTenant({...editableTenant, emailAddress: e.target.value})} value={editableTenant.emailAddress}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine1">Forwarding Address Line 1</label>
							<InputText id="forwardingAddressLine1" onChange={(e) => setEditableTenant({...editableTenant, forwardingAddressLine1: e.target.value})} value={editableTenant.forwardingAddressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine2">Forwarding Address Line 2</label>
							<InputText id="forwardingAddressLine2" onChange={(e) => setEditableTenant({...editableTenant, forwardingAddressLine2: e.target.value})} value={editableTenant.forwardingAddressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine3">Forwarding Address Line 3</label>
							<InputText id="forwardingAddressLine3" onChange={(e) => setEditableTenant({...editableTenant, forwardingAddressLine3: e.target.value})} value={editableTenant.forwardingAddressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="phoneNumber">Phone Number</label>
							<InputText id="phoneNumber" onChange={(e) => setEditableTenant({...editableTenant, phoneNumber: e.target.value})} value={editableTenant.phoneNumber}/>
						</div>
					</Col>
				</Row>
				{isTenantUpdating === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-tenant-confirmation" draggable={false} footer={() => renderRemoveTenantModalFooter()} header="Remove Tenant" modal onHide={() => setShowRemoveTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemoveTenantModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove the tenant?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemoveTenantLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid view-events" draggable={false} header="View Events" modal onHide={() => setShowEventsModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEventsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={modalPropertyEvents}>
							<Column field="description" header="Description" sortable></Column>
							<Column field="eventType" header="Type" sortable style={{width: 250}} body={(rowData) => EventType[rowData.eventType].replace(/([A-Z])/g, " $1")}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			<Dialog className="p-fluid view-tenancy" draggable={false} footer={() => renderViewTenancyModalFooter()} header="View Tenancy Agreement" modal onHide={() => setShowViewTenancyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showViewTenancyModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="startDate">Start Date</label>
							<InputText disabled id="startDate" value={FormatDate(tenant?.tenancyAgreement?.startDate)}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="contractValidUntil">Contract Valid Until</label>
							<InputText disabled id="contractValidUntil" value={FormatDate(tenant?.tenancyAgreement?.contractValidUntil)}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="rentPrice">Rent Price</label>
							<InputNumber disabled id="rentPrice" value={tenant?.tenancyAgreement?.rentPrice || 0}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="rentSchedule">Rent Schedule</label>
							<InputText disabled id="rentSchedule" value={RentSchedule[tenant?.tenancyAgreement?.rentSchedule || 0]}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="generateRentChargeOn">Generate Rent Charge</label>
							<InputText disabled id="generateRentChargeOn" value={FormatDate(tenant?.tenancyAgreement?.generateRentChargeOn)}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="primaryContactTenant">Primary Contact Tenant</label>
							<InputText disabled id="primaryContactTenant" value={tenant?.tenancyAgreement?.primaryContactTenant?.name}/>
						</div>
					</Col>
				</Row>
			</Dialog>
			{isLoading === true &&
				<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 19999}}>
					<ProgressSpinner strokeWidth="5" style={{left: "50%", position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
				</div>
			}
		</>
	);
}