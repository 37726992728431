import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { colours } from "src/app/Styles";
import { HandleAuthTokenRefreshTimer } from "src/app/helpers/Index";
import { LoginDetails } from "src/app/interfaces/Index";
import { AppSettingsService, AuthService } from "src/app/services/Index";

export const AdminLogin = ({toast}: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loginDetails, setLoginDetails] = useState<LoginDetails>(new LoginDetails());

	const login = async () => {
		setIsLoading(true);
		const res = await AuthService.login(loginDetails, "Administrators");
		switch(res.status) {
			case 200:
				const data = await res.json();
				await AppSettingsService.set("accessToken", data.accessToken);
				await AppSettingsService.set("refreshToken", data.refreshToken);
				HandleAuthTokenRefreshTimer();
				setIsLoading(false);
				setLoggedIn(true);
				break;
			default:
				const error = await res.json();
				if (toast.current) (toast.current as any).show({closable: false, detail: error.error, life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	return (
		<div style={{backgroundImage: "url(/assets/splash.jpg)", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "1920px 1080px", height: "100vh", width: "100vw"}}>
			<div style={{alignItems: "center", backgroundColor: colours.transparent, display: "flex", height: "100vh", justifyContent: "center", width: "100vw"}}>
				<div style={{backgroundColor: "rgb(250, 50, 50)", borderRadius: 20, borderWidth: 0, display: "flex", flexDirection: "row", overflow: "hidden"}}>
					<div style={{alignItems: "center", backgroundColor: colours.secondary, display: "flex"}}>
						<img alt="Logo" src="/assets/Logo_GoldCircleWithWhite.png" style={{height: "20vw", margin: "50px 25px 50px 25px", maxHeight: 300, maxWidth: 300, width: "20vw"}}/>
					</div>
					<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: 600, padding: 40}}>
						<div>
							<span style={{color: colours.secondary, fontSize: 35, marginBottom: 10}}>Welcome</span>
							<InputText disabled={isLoading} id="emailAddress" onChange={(e) => setLoginDetails({...loginDetails, emailAddress: e.target.value})} placeholder="Email" style={{marginTop: 20, padding: "0.5rem", width: "100%"}} value={loginDetails.emailAddress}/>
							<InputText disabled={isLoading} id="password" onChange={(e) => setLoginDetails({...loginDetails, password: e.target.value})} placeholder="Password" style={{marginTop: 20, padding: "0.5rem", width: "100%"}} type="password" value={loginDetails.password}/>
						</div>
						<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
							<Link style={{color: colours.primary, display: "block", marginTop: 10, textDecoration: "none"}} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/forgot-password`}>Forgot Password?</Link>
							<Button iconPos="right" label="Login" loading={isLoading} onClick={() => login()} style={{backgroundColor: colours.secondary, borderRadius: 20, borderWidth: 0, color: colours.primary, fontSize: 20, height: 50, minWidth: 150, width: "40%"}}/>
						</div>
						{loggedIn && 
							<Navigate replace={true} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/main`}/>
						}
					</div>
				</div>
			</div>
		</div>
	);
}